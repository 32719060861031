<script setup lang="ts">
const bannerStore = useBannerStore()

onMounted(() => {
	if (bannerStore.row?.length) {
		bannerStore.visit(bannerStore.row.map(b => b.id))
	}
})
</script>

<template>
	<ClientOnly>
		<div
			v-if="bannerStore.row?.length"
			v-bind="$attrs"
			class="overflow-x-auto max-md:w-[100svw] scrollbar-hide overscroll-x-contain bg-white"
		>
			<div
				class="max-md:w-fit flex flex-nowrap gap-x-4 bg-white max-md:px-4 py-2 md:py-2 justify-center"
			>
				<BannerRowItem
					v-for="banner in bannerStore.row"
					:key="banner.id"
					:item="banner"
				/>
			</div>
		</div>
	</ClientOnly>
</template>