<script setup lang="ts">
import type { PropType } from 'vue'
import type { Banner } from '~/types'

defineProps({
	item: {
		type: Object as PropType<Banner>,
		required: true
	}
})

const { t } = useI18n({
	useScope: 'local'
})

const bannerStore = useBannerStore()

const isOpen = ref(false)

const onVisit = (id: number) => {
	bannerStore.click(id)
}

const onOpen = () => {
	isOpen.value = true
}

const onClose = () => {
	isOpen.value = false
}
</script>

<template>
	<div class="relative max-md:h-56 bg-white shadow rounded-md overflow-hidden">
		<a
			:href="item.url"
			:aria-label="item.title"
			target="_blank"
			@click="onVisit(item.id)"
		>
			<UiImage
				:url-server="$isMobile() && !$isTablet() ? item.imageMobile.url : item.imageDesktop.url"
				:url-cdn="$isMobile() && !$isTablet() ? item.imageMobile.cdn_url : item.imageDesktop.cdn_url"
				:width="360"
				:height="240"
				:alt="item.title"
				class="block w-full h-full aspect-[3/2]"
			/>
		</a>
		<div class="absolute top-2 md:top-2.5 left-2 md:left-2.5 bg-black/50 rounded-md text-white/80 text-xs px-1.5 py-0.5">
			{{ t('advertisement') }}
		</div>
		<UButton
			class="absolute top-2 right-2 text-white hover:text-white/80 bg-black/50 px-1 py-1"
			variant="link"
			size="xs"
			icon="i-heroicons-ellipsis-horizontal-20-solid"
			:ui="{
				rounded: 'rounded-full'
			}"
			@click="onOpen"
		/>
		<div
			class="absolute bottom-0 left-0 right-0 w-full h-full bg-white z-10 transition p-2 md:p-4 text-sm"
			:class="[
				isOpen ? 'translate-y-0' : 'translate-y-full'
			]"
		>
			<div class="flex flex-col">
				<div class="max-md:font-xs md:font-semibold max-md:pt-1 max-md:pr-6">
					{{ item.company_name }}
				</div>
				<div class="text-xs mt-0.5">
					<span class="md:font-semibold">{{ t('inn') }}:</span> {{ item.company_inn }}
				</div>
				<div class="text-xs">
					<span class="md:font-semibold">{{ t('erid') }}:</span> {{ item.company_erid }}
				</div>
			</div>
			<UButton
				class="absolute top-1 right-1 text-black"
				variant="link"
				size="md"
				icon="i-heroicons-x-mark-16-solid"
				@click="onClose"
			/>
		</div>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"advertisement": "Реклама",
		"inn": "ИНН",
		"erid": "ERID"
	}
}
</i18n>