<script setup lang="ts">
import { ModuleType, PathType } from '~/enums'
import type { FilterState } from '~/types'
import { TagValue } from '~/enums/tag'

const SearchFilter = defineAsyncComponent(() => import('~/components/search/Filter.vue'))

const { t } = useI18n({
	useScope: 'local'
})

const config = useRuntimeConfig()
const router = useRouter()
const route = useRoute()
const filterStore = useFilterStore()
const filter = useFilter()
const villageStore = useVillageStore()
const buildingStore = useBuildingStore()
const objectStore = useObjectStore()
const tagStore = useTagStore()
const regionStore = useRegionStore()

if (!tagStore.isLoaded) {
	await tagStore.init()
}

if (!regionStore.isLoaded) {
	await regionStore.init()
}

const currentValue = ref('village')
const moduleType = ref(ModuleType.Village)

filterStore.moduleType = moduleType.value
filterStore.state = filter.parseUrl(filterStore.defaultState(), route.params.path as string, route.query)

const path = computed(() => {
	switch (currentValue.value) {
		case 'house':
			return PathType.Buy + '-' + PathType.House
		case 'townhouse':
			return PathType.Buy + '-' + PathType.Townhouse
		case 'plot':
			return PathType.Buy + '-' + PathType.Plot
		case 'flat':
			return PathType.Buy + '-' + PathType.Flat
		case 'building':
			return PathType.Buildings
		default:
			return PathType.Villages
	}
})

const pathType = computed(() => {
	switch (currentValue.value) {
		case 'house':
			return PathType.House
		case 'townhouse':
			return PathType.Townhouse
		case 'plot':
			return PathType.Plot
		case 'building':
			return PathType.Building
		case 'flat':
			return PathType.Flat
		default:
			return PathType.Village
	}
})

const isSelect = (menu: any) => menu.value === currentValue.value

const onSelect = async (menu: any) => {
	currentValue.value = menu.value
	
	moduleType.value = currentValue.value === 'village'
		? ModuleType.Village
		: currentValue.value == 'building'
			? ModuleType.Building
			: ModuleType.Object;
	
	switch (currentValue.value) {
		case 'plot':
			filterStore.state.types = tagStore.findByValues([TagValue.Plot])
			break
		case 'house':
			filterStore.state.types = tagStore.findByValues([TagValue.Cottage, TagValue.Townhouse, TagValue.Duplex])
			break
		case 'townhouse':
			filterStore.state.types = tagStore.findByValues([TagValue.Townhouse])
			break
		default:
			filterStore.state.types = []
			break
	}
	
	onChangeModuleType()
	
	await onChangeState(filterStore.state)
}

const onChangeState = async (state: FilterState) => {
	if (moduleType.value === ModuleType.Village) {
		return await villageStore.searchCount(state)
	}
	if (moduleType.value === ModuleType.Building) {
		return await buildingStore.searchCount(state)
	}

	return await objectStore.searchCount(state)
}

const onChangeModuleType = () => {
	const isVillage = moduleType.value === ModuleType.Village
	const isBuilding = moduleType.value === ModuleType.Building
	const isObject = moduleType.value === ModuleType.Object

	filterStore.subModuleType = moduleType.value === ModuleType.Object && pathType.value === PathType.Flat
		? ModuleType.Building
		: ModuleType.Village
	
	if (import.meta.client) {
		filterStore.getPending = () => {
			if (isVillage) {
				return villageStore.pending
			}
			if (isBuilding) {
				return buildingStore.pending
			}
			return objectStore.pending
		}
		filterStore.getCount = () => {
			if (isVillage) {
				return villageStore.count
			}
			if (isBuilding) {
				return buildingStore.count
			}
			return objectStore.count
		}
		filterStore.onCount = async (state: any) => {
			if (isVillage) {
				return await villageStore.searchCount(state)
			}
			if (isBuilding) {
				return await buildingStore.searchCount(state)
			}
			if (isObject) {
				return await objectStore.searchCount(state)
			}
		}
		filterStore.onSearch = async (state: any) => {
			if (isVillage) {
				return await villageStore.list(state)
			}
			if (isBuilding) {
				return await buildingStore.searchQuery(state)
			}
			if (isObject) {
				return currentValue.value === 'flat'
					? await objectStore.searchQuery(state)
					: await objectStore.searchVillage(state)
			}
		}
		filterStore.onSelect = async (item: any) => {
			if (!item?.slug && !item?.title) {
				return
			}
			if (isBuilding || (isObject && pathType.value === PathType.Flat)) {
				return await filterStore.onCount(filterStore.state)
			}
			isVillage
				? await router.push('/' + PathType.Village + '/' + item.slug)
				: await router.push('/' + path.value + '?search=' + item?.title)
		}
	}
}

const onConfirm = async (state: FilterState) => {
	const { fullUrl } = filter.makeUrl(state, path.value)
	await router.push(fullUrl)
}

onChangeModuleType()
await onChangeState(filterStore.state)

const menus = [{
	label: t('menu.village'),
	icon: 'i-app-village',
	value: 'village'
}, {
	label: t('menu.house'),
	icon: 'i-app-house',
	value: 'house'
}, {
	label: t('menu.plot'),
	icon: 'i-app-plot',
	value: 'plot'
}, {
	label: t('menu.townhouse'),
	icon: 'i-app-townhouse',
	value: 'townhouse'
}, ...config.public.BUILDING_ENABLED ? [
	{
		label: t('menu.building'),
		icon: 'i-app-building',
		value: 'building'
	}
] : [],
	...config.public.FLAT_ENABLED ? [{
	label: t('menu.flat'),
	icon: 'i-app-flat',
	value: 'flat'
}] : []]
</script>

<template>
	<div class="w-full h-[380px] md:h-[450px] bg-[url('/images/index/bg.jpg')] bg-cover bg-center">
		<LayoutContainer class="pt-8 md:pt-16">
			<h1 class="text-white text-2xl md:text-4xl font-bold px-4 md:px-0 mb-0">
				{{ t('title') }}
			</h1>
			<div
				class="md:w-fit grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-[10px] text-teal-800 mt-5 px-4 md:px-0"
			>
				<div
					v-for="menu in menus"
					:key="menu.label"
					class="flex items-center gap-5 px-3 py-2 lg:px-5 lg:py-3 ring-1 md:w-[170px] lg:w-[230px] rounded-lg ring-stone-300 hover:ring-teal-800 cursor-pointer transition"
					:class="[
						isSelect(menu) ? 'bg-teal-800 text-white ring-teal-800' : 'bg-white hover:bg-slate-200 hover:ring-teal-800',
					]"
					@click="onSelect(menu)"
				>
					<UIcon
						:name="menu.icon"
						class="w-8 h-8 lg:w-8 lg:h-8"
					/>
					<div class="text-base">
						{{ menu.label }}
					</div>
				</div>
			</div>
			<SearchFilter
				:module-type="moduleType"
				:path-type="pathType"
				:path="path"
				:use-query="currentValue == 'building' || currentValue == 'flat'"
				:show-installed="false"
				:show-confirm-button="true"
				:change-history="false"
				:on-confirm-button="onConfirm"
				:on-change="onChangeState"
				class="mt-8 md:mt-[50px] lg:mt-[72px] !bg-transparent"
			/>
		</LayoutContainer>
	</div>
	<div class="flex md:hidden justify-center mt-4 px-4">
		<UButton
			variant="outline"
			block
		>
			<SearchFilterConfirm
				text="filter.button.show"
				@click="onConfirm(filterStore.state)"
			/>
		</UButton>
	</div>
</template>

<i18n>
{
	"ru": {
		"title": "Что вы ищите?",
		"menu": {
			"village": "Поселки",
			"house": "Дома",
			"plot": "Участки",
			"townhouse": "Таунхаусы",
			"building": "Новостройки",
			"flat": "Квартиры"
		}
	}
}
</i18n>