<script setup lang="ts">

const bannerStore = useBannerStore()

import.meta.server
	? await bannerStore.random()
	: bannerStore.random().then()

definePageMeta({
	layout: 'main'
})

</script>

<template>
	<IndexFilter />
	<IndexPopular />
	<IndexObjects />
	<IndexBanners />
	<IndexVillages />
</template>