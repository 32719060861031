import type { Banner } from '~/types'

interface State {
  row: Banner[] | undefined
  background: Banner[] | undefined
  right: Banner[] | undefined
  rowAfter: number
  backgroundAfter: number,
  perPage: number
  onClick: Function | undefined
}

export const useBannerStore = defineStore('banner', {
  state: (): State => {
    return <State>{
      row: undefined,
      background: undefined,
      right: undefined,
      rowAfter: 5,
      backgroundAfter: 10,
      perPage: 20,
      onClick: undefined,
    }
  },
  actions: {
    async random() {
      const result = await useAPIFetch<{
        row: Banner[],
        background: Banner[],
        right: Banner[],
        rowAfter: number,
        backgroundAfter: number
      }>('/v1/banners/random')

      this.row = result.row
      this.background = result.background
      this.right = result.right
      this.rowAfter = result.rowAfter
      this.backgroundAfter = result.backgroundAfter
    },
    visit(ids: number[]) {
      useAPIFetch('/v1/banners/visit', {
        method: 'POST',
        body: {
          ids: ids.join(',')
        }
      }).then(() => {})
    },
    click(id: number) {
      useAPIFetch('/v1/banners/click', {
        method: 'POST',
        body: {
          id,
        }
      }).then(() => {})
    }
  }
})
